import React, { useEffect, useRef, useState } from 'react';
import '../../Layout/_reset.scss';
import useLogin from '../../utils/useLogin';
import style from './ExportIframe.module.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import InfoText from '../../Block/InfoText';
import { getEventById } from '../../utils/apiClient';
import { redirectToDashboard } from '../../utils/redirects';

export default function ExportIframe() {
    const { eventId } = useParams();
    const [userData] = useLogin() || [];
    const textRef = useRef();
    const { t } = useTranslation();
    const [event, setEvent] = useState(null);

    useEffect(() => {
        getEventById(eventId).then((event) => {
            setEvent(event.data);
        });
    }, [eventId]);

    if (!userData) {
        return <div className={style.wrapper}>Loading...</div>;
    }

    function handleCopy() {
        navigator.clipboard.writeText(textRef.current.value);
        enqueueSnackbar(t('iframe_export.copied_to_clipboard'), {
            variant: 'success',
            key: 'copied_to_clipboard',
            preventDuplicate: true,
        });
    }

    const host = encodeURI(
        window.location.protocol + '//' + window.location.host,
    );
    const eventName = event?.name
        .replace(/[\u00A0-\u9999<>&]/g, (i) => '&#' + i.charCodeAt(0) + ';')
        .replace(/["']/g, '&quot;');

    let iframeHtml = `
        <iframe title="${eventName}" src="${host}/app/q/${eventId}" style="width: 100%; height: 100%"></iframe>
    `.trim();

    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                <div className={style.title}>
                    <h2>{event?.name}</h2>
                </div>
                <div>
                    <textarea
                        ref={textRef}
                        onDoubleClickCapture={handleCopy}
                        rows={5}
                        cols={60}
                        readOnly
                        value={iframeHtml}
                    />
                </div>
                <div className={style.actions}>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={redirectToDashboard}
                    >
                        {t('iframe_export.dashboard_btn')}
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleCopy}
                        startIcon={<ContentCopyIcon />}
                    >
                        {t('iframe_export.copy_btn')}
                    </Button>
                </div>
                <InfoText type="info">{t('iframe_export.info_text')}</InfoText>
            </div>
        </div>
    );
}
